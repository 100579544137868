@charset "utf-8";
/*============================================

  ACCESS

============================================*/
/*-------------------------------
  ACCESS > COMMON
-------------------------------*/
.p-access{
  &__block{
    &:nth-child(n+2){
      margin-top: $margin_ll;
    }
    @include mq(sm){
      &:nth-child(n+2){
        margin-top: $margin_lg;
      }
    }
  }
  &__leading{
    margin-bottom: 3em;
  }
  @include mq(ss-){
    &__leading{
      text-align: center;
    }
  }
}

/*-------------------------------
  ACCESS > HALL
-------------------------------*/
.p-access_hall{
  &__box{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &:nth-last-child(n+2){
      margin-bottom: $margin_md;
      padding-bottom: $margin_md;
      border-bottom:dashed 1px #e5e5e5;
    }
  }
  &__view{
    position: relative;
    width: 40%;
    padding-top: 30%;
  }
  &__ttl{
    font-size: 1.875em;
    font-weight: 400;
    color: $main_color;
    margin-bottom: 1em;
  }
  &__txt{
    padding-left: $margin_md;
    width: 55%;
  }
  &__img{
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > *{
      width: 48%;
    }
  }
  @include mq(sm){
    &__box{
      display: block;
    }
    &__txt{
      width: 100%;
      padding-left: 0;
    }
    &__ttl{
      font-size: 1.75em;
    }
    &__view{
      margin-top: 4%;
      width: 100%;
      padding-top: 40%;
    }
  }
  @include mq(ss){
    &__ttl{
      font-size: 1.5em;
    }
    &__img{
      display: block;
      > *{
        width: 100%;
        &:nth-child(n+2){
          margin-top: $margin_sm;
        }
      }
    }
    &__view{
      margin-top: $margin_sm;
      padding-top: 75%;
    }
  }
}

/*-------------------------------
  ACCESS > ROOM
-------------------------------*/
.p-access_room{
  &__img{
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    > li{
      width: 31%;
      span{
        display: block;
        font-size: 1.125em;
        line-height: 1;
        margin-top: .75em;
      }
      @include mq(ss-){
        margin-right: 3.5%;
        &:nth-child(3n){
          margin-right: 0;
        }
        &:nth-child(n+4){
          margin-top: 2%;
        }
      }
      @include mq(ss){
        width: 48%;
        &:nth-child(n+3){
          margin-top: 4%;
        }
      }
    }
  }
  @include mq(xs){
    &__img{
      > li{
        span{
          font-size: 1em;
        }
      }
    }
  }
}
/*-------------------------------
  ACCESS > INFO
-------------------------------*/
.p-access_info{
  &__box{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &.-bottom{
      margin-top: $margin_md;
    }
  }
  &__map{
    position: relative;
    width: 40%;
    padding-top: 30%;
  }
  &__txt{
    width: 60%;
    padding-left: $margin_md;
  }
  &__list{
    margin-top: 2em;
    dt{
      position: relative;
      font-size: 1.5em;
      font-weight: 400;
      line-height: 1;
      padding-left: 1.25em;
      margin-bottom: .5em;
      &:before{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        background: $sub_color;
        transform: scale(.5)
      }
    }
    dd{
      &:nth-last-child(n+2){
        margin-bottom: 2em;
      }
    }
  }
  &__detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 48%;
    figure{
      width: 40%;
    }
    aside{
      width: 55%;
      h3{
        color: $sub_color;
        font-weight: 400;
        font-size: 1.125em;
        line-height: 1.4;
      }
      p{
        margin-top: .5em;
        font-size: .875em;
      }
    }
  }
  @include mq(sm){
    &__box{
      &.-bottom{
        display: block;
      }
    }
    &__list{
      dt{
        font-size: 1.25em;
      }
    }
    &__detail{
      width: 100%;
      figure{
        width: 30%;
      }
      aside{
        width: 65%;
      }
      &:nth-child(n+2){
        margin-top: $margin_md;
      }
    }
  }
  @include mq(xs){
    &__box{
      display: block;
    }
    &__map{
      width: 100%;
      padding-top: 50%;
    }
    &__txt{
      margin-top: $margin_sm;
      width: 100%;
      padding-left: 0;
    }
  }
  @include mq(ss){
    &__detail{
      display: block;
      figure,
      aside{
        width: 100%;
      }
      aside{
        margin-top: $margin_ss;
      }
    }
  }
}
