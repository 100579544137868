@charset "utf-8";
/*============================================

  COMPANY

============================================*/
/*-------------------------------
  COMPANY > COMMON
-------------------------------*/
.p-company{
  &__block{
    &:nth-child(n+2){
      margin-top: 5em;
    }
  }
  &__cont{
    text-align: center;
  }
  &__ttl{
    margin-bottom: 2em;
    text-align: center;
  }
  &__access{
    position: relative;
    padding-top: 45rem;
    width: 100%;
  }
  &__map{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @include mq(xs){
    &__block{
      &:nth-child(n+2){
        margin-top: 4em;
      }
    }
    &__ttl{
      margin-bottom: 1.5em;      
    }
    &__access{
      padding-top: 40rem;
    }
  }
  @include mq(ss){
    &__access{
      padding-top: 35rem;
    }
  }
  @include mq(screen){
    &__block{
      @include animation('fadeUp');
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
  }
}
