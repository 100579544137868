@charset "utf-8";
/*============================================

  CONTACT

============================================*/
/*-------------------------------
  CONTACT > COMMON
-------------------------------*/
.p-contact{
  p{
    margin-bottom: 7rem;
  }
  &__tbl{
    margin-bottom: 3em;
    tr{
      th,td{
        @include mq(xs-){
          border-bottom: solid 1px $sub-color;
        }
      }
      td{
        width: 70%;
      }
    }
  }
  &__label{
    width: 30%;
    padding: 2.5em 1.875em;
    text-align: left;
    &::before{
      content: '任意';
      background: $font-color;
      color: #fff;
      line-height: 1;
      font-size: .875em;
      padding: .25em .75em;
      margin-right: 1.25em;
    }
    &.-required{
      &::before{
        content: '必須';
        background: #df1b1b;
      }
    }
  }
  &__thanks{
    text-align: center;
    span{
      font-size: 2em;
    }
    p{
      margin-top: 1em;
    }
  }
  @include mq(lg){
    p{
      margin-bottom: 5.6rem;
    }
  }
  @include mq(sm){
    &__label{
      padding: 2.5em 1.5em;
    }
  }
  @include mq(xs){
    p{
      margin-bottom: 4.48rem;
    }
    &__label{
      width: 100%;
      padding: 2em 1em;
    }
    &__tbl{
      td{
        width: 100%;
      }
    }
    &__thanks{
      span{
        font-size: 1.5em;
      }
      p{
        margin-top: 1em;
        text-align: left;
      }
    }
  }
}

/*-------------------------------
  CONTACT > PRIVACY
-------------------------------*/
.p-contact_privacy{
  border: solid 3px $sub-color;
  padding: 3rem;
  max-height: 25em;
  overflow: hidden;
  overflow-y: scroll;
  word-break: break-all;
  p{
    margin-bottom: 2rem;
    font-size: .875em;
  }
  &__block{
    &:nth-child(n+2){
      margin-top: 2rem;
    }
  }
  &__ttl{
    font-size: 1em;
    font-weight: 500;
    span{
      position: relative;
      display: block;
      padding: .25em 0;
      border-bottom: 1px solid $sub_color;
      line-height: 1.34;
      &::after{
        position: absolute;
        content: '';
        width: 8rem;
        height: 1px;
        background: $main_color;
        bottom: -1px;
        left: 0;
      }
    }
  }
  &__txt{
    font-size: .875em;
    a{
      color: $main_color;
      text-decoration: underline;
      transition: .4s;
      &:hover{
        text-decoration: none;
      }
    }
    &:nth-child(n+2){
      margin-top: 1em;
    }
  }
  &__list{
    margin-top: 1em;
    li{
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 1em;
      font-size: .875em;
      &::before{
        content: '';
        position: absolute;
        top: 1em;
        left: 0;
        transform: translate(0, -50%);
        width: .5em;
        height: .5em;
        background: $main_color;
        border-radius: 50%;
        display: inline-block;
        margin-right: .5em;
      }
    }
  }
  &__agree{
    margin: 1em 0 2em;
    text-align: center;
  }
  @include mq(lg){
    &__block{
      &:nth-child(n+2){
        margin-top: 2rem;
      }
    }
    &__ttl{
      span{
        &::after{
          width: 6.4rem;
        }
      }
    }
  }
  @include mq(sm){

  }
  @include mq(xs){  
    padding: 2rem;
  }
  @include mq(ss){
    &__block{
      &:nth-child(n+2){
        margin-top: 1rem;
      }
    }
    &__ttl{
      font-size: 1.3125em;
      span{
        &::after{
          width: 5rem;
        }
      }
    }
  }
}

