@charset "utf-8";
/*============================================

  LIST PRIMARY

============================================*/
.c-list_pr{
}
/*============================================

  LIST NUMBER

============================================*/
.c-list_num{
}
/*============================================

  LIST DL

============================================*/
.c-list_dl{
  padding: $margin_ss*2;
  > li{
    padding: $margin_ss $margin_sm;
    border-bottom:solid 1px #e5e5e5;
    &:nth-child(1){
      padding-top: 0;
    }
  }
  &__ttl{
    position: relative;
    padding-left: 1.5em;
    line-height: 1.4;
    font-size: 1.375em;
    &:before{
      position: absolute;
      content: "";
      left: 0;
      top: .2em;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: $sub_color;
      transform: scale(.545);
    }
  }
  &__cont{
    margin-top: 1em;
  }
  @include mq(ss-){
    &.-col2{
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      > li{
        width: 48%;
        &:nth-child(2){
          padding-top: 0;
        }
      }
    }
  }
  @include mq(xs){
    padding: $margin_sm $margin_ss;
    > li{
      padding: $margin_ss 0;
    }
  }
}
