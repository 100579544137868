@charset "utf-8";
/*============================================

  BUTTON WRAP

============================================*/
.c-btnwrap{
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(ss){
    text-align: center;
  }
}
/*============================================

  BUTTON PLAIN

============================================*/
.c-btn_pl{
  display: inline-block;
  background: #fff;
  border:solid 1px #000;
  color: #000;
  padding: 1em;
  width: 100%;
  text-align: center;
  line-height: 1.5;
  transition: .4s;
  span{
    position: relative;
    display: inline-block;
    padding-left: 1.625em;
    &:before{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-45%);
      @include icon('arrow-fill_20');
    }
  }
  &.-blu{
    background: $main_color;
    border-color: #fff;
    color: #fff;
    @include mq(sm-){
      &:hover{
        background: #fff;
        color: $main_color;
      }
    }
  }
  @include mq(ss-){
    max-width: 300px;
  }
  @include mq(sm-){
    &:hover{
      background: #000;
      color: #fff;
    }
  }
  @include mq(ss){
    padding: .75em 0;
  }
}
/*============================================

  BUTTON LARGE

============================================*/

.c-btn_lg{
  display: inline-block;
  background: #fff;
  border:solid 1px #000;
  color: #000;
  padding: 1em;
  width: 100%;
  text-align: center;
  line-height: 1.5;
  transition: .4s;
  span{
    position: relative;
    display: inline-block;
    padding-left: 1.625em;
    &:before{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-45%);
      @include icon('arrow-fill_20');
    }
  }
  &.-blu{
    background: $main_color;
    border-color: #fff;
    color: #fff;
    @include mq(sm-){
      &:hover{
        background: #fff;
        color: $main_color;
      }
    }
  }
  @include mq(sm-){
    &:hover{
      background: #000;
      color: #fff;
    }
  }
  @include mq(ss){
    padding: .75em 0;
  }
}

/*============================================

  BUTTON LINE

============================================*/
.c-btn_line{
  display: block;
  position: relative;
  padding-bottom: 1em;
  width: 100%;
  border-bottom: solid 1px $sub_color;
  text-align: left;
  line-height: 1.5;
  >*{
    display: block;
  }
  span{
    font-size: 1.75em;
  }
  em{
    font-size: .875em;
    color: $main-color;
    font-family: $base_font_en;
  }
  &::after{
    position: absolute;
    content: '';
    width: 0;
    left: 0;
    bottom: -1px;
    height: 1px;
    transition: .4s;
    background: $main-color;
  }
  @include mq(sm-){
    &:hover{
      &::after{
        width: 100%;
      }
    }
  }
  @include mq(sm){
    span{
      font-size: 1.5em;
    }
  }
}