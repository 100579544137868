@charset "utf-8";
/*--------------------------------------------
  KEYFRAME
--------------------------------------------*/
@keyframes ripple{
  0%{
    opacity: 1;
    width: 50;
    padding-top: 50;
  }
  16.66666%{
    opacity: 0;
    width: 150%;
    padding-top: 150%;
  }
  100%{
    opacity: 0;
    width: 50%;
    padding-top: 50%;
  }
}
@keyframes draw_path {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: 1;
    fill-opacity:0;
  }

  75% {
    stroke-dashoffset: 0;
    stroke-width: 1;
    fill-opacity: 0;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    fill-opacity: 1;
  }
}
@keyframes hover_light {
  100% { 
    outline-color: transparent;
    outline-offset: .75em;
  }
}
/*--------------------------------------------
  MIXIN
--------------------------------------------*/
@mixin animation($type: 'fadeUp',$status: 'before'){
  @if $type == 'fadeUp' {
    @if $status == 'before' {
      transition-property:transform,opacity;
      transform:translateY($margin_md);
      transition-duration: 2s;
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateY(0);
      opacity: 1;
    }
  }
  @else if $type == 'fadeLeft' {
    @if $status == 'before' {
      transition-property: transform, opacity;
      transform:translateX($margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateX(0);
      opacity: 1;
    }
  }

  @else if $type == 'fadeRight' {
    @if $status == 'before' {
      transition-property: transform, opacity;
      transform:translateX(-$margin_sm);
      opacity: 0;
    }@else if $status == 'after' {
      transform:translateX(0);
      opacity: 1;
    }
  }

  @else if $type == 'fadeIn' {
    @if $status == 'before' {
      transition-property: opacity;
      opacity: 0;
    }@else if $status == 'after' {
      opacity: 1;
    }
  }

  @else if $type == 'scaleUp' {
    @if $status == 'before' {
      transition-property: transform;
      transform:scale(0);
    }@else if $status == 'after' {
      transform:scale(1);
    }
  }
}

/*--------------------------------------------
  ANIMATION
--------------------------------------------*/

// .animation{
//   transition: all 1.0s ease-in-out;
//   position: relative;
//   &.up{
//     opacity: 0;
//     transform:translateY($margin_md);
//   }
//   &.is-anime{
//     opacity: 1;
//   }
//   &.up {
//     &.is-anime{
//       transform: translateY(0);
//     }
//   }

// }