@charset "utf-8";
/*============================================

  FOOTER

============================================*/
.l-footer{
  &__top{
    background: $main_color;
  }
  &__inner{
    max-width: 1200px;
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 3.75em 0 3.125em;
    &.-btm{
      justify-content: space-between;
    }
  }
  &__btn{
    width: 50%;
    background: #fff;
    text-align: center;
    line-height: 1;
    a{
      padding: 4.0625em 0;
      width: 100%;
      height: 100%;
      display: block;
      transition: .4s;
      @include mq(sm-){
        &:hover{
          background: #000;
          color: #fff;
        }
      }
    }
    em{
      display: block;
      margin-bottom: .625em;
    }
    span{
      font-family: $base_font_en;
      display: block;
    }
    &.-tel{
      span{
        font-size: 1.875em;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before{
          @include icon('phone-fill_01');
          font-size: .6em;
          margin-right: .6em;
          vertical-align: middle;
        }
      }
      @include mq(sm-){
        pointer-events: none;
      }
    }
    &.-mail{
      margin-left: 1px;
      span{
        font-size: 1.5em;
        &:before{
          @include icon('email-thin_01');
          font-size: .83em;
          margin-right: .625em;
          vertical-align: middle;
        }
      }
    }
  }
  &__logo{
    margin-bottom: .625em;
    width: 15em;
  }
  &__right{
    font-size: .875em;
    line-height: 1;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &__link{
    display: flex;
  }
  &__nav{
    &:nth-child(n+2){
      margin-left: 1.25em;
    }
  }
  &__hover{
    z-index: 2;
    position: relative;
    &:before{
      z-index: 1;
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 1px;
      transform: scaleX(0);
      background-color: #241e1c;
      content: "";
      transition: all .25s cubic-bezier(1,0,0,1);
      transition-timing-function: cubic-bezier(1,0,0,1);
    }
    &:hover{
      color: $main_color;
    }
    &:hover::before{
      transform: scaleX(1);
      background-color: #006ff0;
    }
  }
  &__copy{
    margin-top: 1.875em;
  }
  @include mq(lg){
    &__inner{
      max-width: 960px;
      padding: 3.75em 0 3.125em;
    }
  }
  @include mq(md){
    &__inner{
      padding: 3em 0 2.5em;
    }
  }
  @include mq(sm){
    &__inner{
      padding: 2.4em 0 2.4em;
    }
    &__btn{
      a{
        padding: 3.2em 0;
      }
      em{
        margin-bottom: .625em;
      }
    }
  }
  @include mq(xs){
    &__inner{
      padding: 1.92em 0;
      &.-btm{
        display: block;
      }
    }
    &__btn{
      width: 100%;
      a{
        padding: 2.56em 0;
      }
    }
    &__left{
      text-align: center;
      width: 100%;
    }
    &__logo{
      margin: 0 auto 0.625em;
    }
    &__right{
      width: 100%;
      text-align: center;
      display: block;
    }
    &__link{
      display: none;
    }
    &__copy{
      margin-top: 1.5em;
    }
  }
  @include mq(ss){
    &__inner{
      display: block;
      justify-content: center;
      &.-btm{
        justify-content: center;
      }
    }
    &__btn{
      a{
        padding: 2.048em 0;
      }
      &.-mail{
        margin-left: 0;
        margin-top: 1px;
      }
    }
    &__logo{
      width: 12em;
    }
  }

}

