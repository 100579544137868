@charset "utf-8";
/*============================================

  EDITOR

============================================*/
.p-editor{
  a {
    text-decoration: underline;
    @include mq(sm-){
      &:hover{
        text-decoration: none;
      }
    }
  }
  em {
    font-style: italic;
  }
  strong,b{
    font-weight: bold;
  }
  u{
    text-decoration: underline;
  }
  span{
    &.red{
      color: #ff0000;
    }
    &.cyan{
      color: #00ffff;
    }
    &.orange{
      color: #ffa500;
    }
    &.navy{
      color: #000080;
    }
    &.yellow{
      color: #ffff00;
    }
    &.blue{
      color: #0000ff;
    }
    &.purple{
      color: #4b0082;
    }
    &.magenta{
      color: #ff00ff;
    }
    &.pink{
      color: #ff1493;
    }

    &.bg_red{
      color: #ff0000;
    }
    &.bg_cyan{
      color: #00ffff;
    }
    &.bg_orange{
      color: #ffa500;
    }
    &.bg_navy{
      color: #000080;
    }
    &.bg_yellow{
      color: #ffff00;
    }
    &.bg_blue{
      color: #0000ff;
    }
    &.bg_purple{
      color: #4b0082;
    }
    &.bg_magenta{
      color: #ff00ff;
    }
    &.bg_pink{
      color: #ff1493;
    }
  }
}