@charset "utf-8";
/*============================================

  NEWS

============================================*/
/*-------------------------------
  NEWS > COMMON
-------------------------------*/
.p-news{
  &__tab{
    margin-bottom: 3em;
  }
  &__flex{
    display: flex;
    align-items: center;
  }
  &__day{
    margin-right: 1em;
    font-weight: 600;
    font-family: $base_font_en;
    line-height: 1;
  }
  &__ctg{
    font-size: .75em;
    background: $main_color;
    color: #fff;
    padding: 0 1em;
  }
  &__ttl{
    font-size: 1.875em;
    font-weight: 500;
    margin-bottom: 1em;
    line-height: 1.34;
    span{
      position: relative;
      display: block;
      padding: .75em 0;
      border-bottom: 1px solid $sub_color;
      line-height: 1.34;
      &::after{
        position: absolute;
        content: '';
        width: 10rem;
        height: 1px;
        background: $main_color;
        bottom: -1px;
        left: 0;
      }
    }
  }
  &__btn{
    margin-top: 4em;
    text-align: center;
  }
  @include mq(lg){
    &__ttl{
      span{
        &::after{
          width: 8rem;
        }
      }
    }
  }
  @include mq(sm){
    &__ttl{
      font-size:1.5em;
    }
    &__btn{
      margin-top: 3em;
    }
  }
  @include mq(ss){
    &__ttl{
      font-size: 1.3125em;
      span{
        &::after{
          width: 6.4rem;
        }
      }
    }
  }
}
