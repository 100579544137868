/*--------------------------------------------
  BASE
--------------------------------------------*/
@-ms-viewport {
  width: auto;
  initial-scale: 1;
}

html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  @include mq(md) {
    font-size: .8vw;
  }
  @include mq(sm) {
    font-size: 1vw;
  }
  @include mq(xs) {
    font-size: 1.4vw;
  }
  @include mq(ss) {
    font-size: 2.35vw;
  }
}

body {
  font-family: $base_font;
  font-weight: 500;
  font-size: 1.6rem;
  color: $font_color;
  line-height: 1.875;
  -webkit-font-smoothing: antialiased;
  @include mq(md-lg) {
    font-size: 1.4rem;
  }
  @include mq(xs) {
    line-height: 1.6;
  }
  &.is-modalOpen{
    overflow: hidden;
  }
}
.grecaptcha-badge{
  z-index: 50;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

main {
  display: block;
}

::placeholder{
  color:#cccccc;
}

img {
  vertical-align: middle;
}

table {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;

  &[href=""]{
    pointer-events: none;
  }
  @include mq(sm-) {
    transition: $transition_val;
  }
}


svg {
  width: 1em;
  height: 1em;
  fill: $font_color;
}


h1,h2,h3,h4,h5,h6,th,dt,strong,b{
  font-weight: 600;
  line-height: 1;
}

img{
  max-width: 100%;
  height: auto;
}

em{
  font-style: normal;
}