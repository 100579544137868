@charset "utf-8";
/*============================================

  CONTAINER

============================================*/
.c-inner{
  width: 94%;
  max-width: $base_width;
  margin-left: auto;
  margin-right: auto;
  &.-full{
    max-width: 94%;
  }
}
/*============================================

  WRAP

============================================*/
.c-boxpd{
  padding: $margin_md*2 0;
  &_xl{
    padding: $margin_md*3 0;
  }
  &_ll{
    padding: $margin_ll 0;
  }
  &_lg{
    padding: $margin_lg 0;
  }
  &_md{
    padding: $margin_md 0;
  }
  &_sm{
    padding: $margin_sm 0;
  }
  &_ss{
    padding: $margin_ss 0;
  }
  @include mq(sm){
    padding: $margin_lg 0;
    &_xl{
      padding: $margin_md*2 0;
    }
    &_ll{
      padding: $margin_sm*3 0;
    }
    &_lg{
      padding: $margin_md 0;
    }
    &_md{
      padding: $margin_sm 0;
    }
    &_sm{
      padding: $margin_ss 0;
    }
    &_ss{
      padding: $margin_ss/2 0;
    }
  }
}
/*============================================

  LEADING

============================================*/
.c-leading{
  border-top:solid 1px #e5e5e5;
  border-bottom:solid 1px #e5e5e5;
  padding: $margin_sm 0;
}
/*============================================

  IFRAME

============================================*/
.c-iframe{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/*============================================

  GOOGLE MAP

============================================*/
.c-gmap{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/*============================================

  COLUMN

============================================*/
.c-flex_container{
  overflow: hidden;
}
.c-flex{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  @include mq(sm-){
    &.-col2{
      > *{
        width: calc(100% / 2);
      }
    }
    &.-col3{
      > *{
        width: calc(100% / 3);
      }
    }
    &.-col4{
      > *{
        width: calc(100% / 4);
      }
    }
  }
  @include mq(ss-){
    margin-left: -$margin_md/2;
    margin-right: -$margin_md/2;
    margin-top: -$margin_md;
    >*{
      padding:$margin_md $margin_md/2 0;
    }
    &.-gapsm{
      margin-left: -$margin_sm/2;
      margin-right: -$margin_sm/2;
      margin-top: -$margin_sm;
      > *{
        padding:$margin_sm $margin_sm/2 0;
      }
    }
    &.-gapss{
      margin-left: -$margin_ss/2;
      margin-right: -$margin_ss/2;
      margin-top: -$margin_ss;
      > *{
        padding:$margin_ss $margin_ss/2 0;
      }
    }
  }
  @include mq(xs-sm){
    &.-sm_col1{
      display: block;
      margin: 0;
      > *{
        width: 100%;
        padding: 0;
        &:nth-child(n+2){
          margin-top:$margin_md;
        }
      }
      &.-gapsm{
        margin: 0;
        > *{
          padding: 0;
          &:nth-child(n+2){
            margin-top:$margin_sm;
          }
        }

      }
      &.-gapss{
        margin: 0;
        > *{
          padding: 0;
          &:nth-child(n+2){
            margin-top:$margin_ss;
          }
        }
      }
    }
    &.-sm_col2{
      > *{
        width: calc(100% / 2);
      }
    }
    &.-sm_col3{
      > *{
        width: calc(100% / 3);
      }
    }
    &.-sm_col4{
      > *{
        width: calc(100% / 4);
      }
    }
  }
  @include mq(ss-xs){
    &.-xs_col1{
      display: block;
      margin: 0;
      > *{
        width: 100%;
        padding: 0;
        &:nth-child(n+2){
          margin-top:$margin_md;
        }
      }
      &.-gapsm{
        margin: 0;
        > *{
          padding: 0;
          &:nth-child(n+2){
            margin-top:$margin_sm;
          }
        }

      }
      &.-gapss{
        margin: 0;
        > *{
          padding: 0;
          &:nth-child(n+2){
            margin-top:$margin_ss;
          }
        }
      }
    }
    &.-xs_col2{
      > *{
        width: calc(100% / 2);
      }
    }
    &.-xs_col3{
      > *{
        width: calc(100% / 3);
      }
    }
    &.-xs_col4{
      > *{
        width: calc(100% / 4);
      }
    }
  }
  @include mq(ss){
    &:not(.-ss_col2){
      display: block;
      > *{
        &:nth-child(n+2){
          margin-top: $margin_sm;
        }
      }
    }
    &.-ss_col2{
      >*{
        width: 48%;
        &:nth-child(even){
          margin-left: 4%;
        }
        &:nth-child(n+3){
          margin-top:4%;
        }
      }
    }
  }
}
