@charset "utf-8";

/*
SP MENU SAMPLE - HTML BTN + JS
------------------------------

<nav class="slideout-menu" id="js-so_menu">
  <div class="menu-inner">
  </div>
</nav>

<div class="slideout-panel" id="js-so_panel"></div>

<header></header>


<button type="button" class="slideout-hamburger" id="js-so-toggle">
  <span class="sr-only">MENU</span>
  <span class="hamburger-icon"></span>
</button>


// slideout JS
var slideout = new Slideout({
  'panel': document.getElementById('js-so_panel'),
  'menu': document.getElementById('js-so_menu'),
  'duration': 10,
});
var nodeList = document.querySelectorAll('#js-so-toggle, #js-so_panel');
var node = Array.prototype.slice.call(nodeList,0);
node.forEach(function(elem, index){
  elem.addEventListener('click', function() {
    slideout.toggle();
  });
});
*/



.slideout-open,
.slideout-open body {
  overflow: hidden;
}

.slideout-menu {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  z-index: 101;
  width: 100%;
  background-color: #333;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: .6s cubic-bezier(0.190, 1.000, 0.220, 1.000);
}
.slideout-open .slideout-menu {
  right: 0;
}
.slideout-menu .menu-inner {
  padding: 6em 1.5em;
}


.slideout-panel {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  will-change: transform;
  transform: translateX(0) !important;
  opacity: 0;
  transition: opacity .6s cubic-bezier(0.190, 1.000, 0.220, 1.000);
  pointer-events: none;
}
.slideout-open .slideout-panel {
  z-index: 100;
  opacity: 1;
  pointer-events: auto;
}



/*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
.slideout-hamburger {
  display: block;
  width: 4.5em;
  height: 100%;
  padding: 2em 1em;
  -webkit-transition: all .6s cubic-bezier(0.190, 1.000, 0.220, 1.000);
  transition: all .6s cubic-bezier(0.190, 1.000, 0.220, 1.000);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: #222;
  cursor: pointer;
}
.hamburger-icon {
  position: relative;
  display: block;
}
.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  width: 100%;
  height: .2rem;
  -webkit-transition: all .6s cubic-bezier(0.190, 1.000, 0.220, 1.000);
  transition: all .6s cubic-bezier(0.190, 1.000, 0.220, 1.000);
  background-color: #fff;
}
.hamburger-icon::before,
.hamburger-icon::after {
  content: '';
  display: block;
  position: absolute;
  top: -.6em;
  left: 0;
}
.hamburger-icon::after {
  top: .6em;
}
.slideout-open .hamburger-icon {
  background-color: transparent;
}
.slideout-open .hamburger-icon::before,
.slideout-open .hamburger-icon::after {
  top: 0;
}
.slideout-open .hamburger-icon::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.slideout-open .hamburger-icon::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
