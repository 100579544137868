@charset "utf-8";
/*============================================

  PRIVACY

============================================*/
/*-------------------------------
  PRIVACY > COMMON
-------------------------------*/
.p-privacy{
  p{
    margin-bottom: 7rem;
  }
  @include mq(lg){
    p{
      margin-bottom: 5.6rem;
    }
  }
  @include mq(xs){
    p{
      margin-bottom: 4.48rem;
    }
  }
  @include mq(screen){
    &__block{
      @include animation('fadeUp');
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
  }
}
/*-------------------------------
  PRIVACY > INNER
-------------------------------*/
.p-privacy_inner{
  &__block{
    &:nth-child(n+2){
      margin-top: 5rem;
    }
    a{
      word-break: break-all;
    }
  }
  &__ttl{
    font-size: 1.875em;
    font-weight: 500;
    margin-bottom: 1em;
    span{
      position: relative;
      display: block;
      padding: .75em 0;
      border-bottom: 1px solid $sub_color;
      line-height: 1.34;
      &::after{
        position: absolute;
        content: '';
        width: 10rem;
        height: 1px;
        background: $main_color;
        bottom: -1px;
        left: 0;
      }
    }
  }
  &__txt{
    a{
      color: $main_color;
      text-decoration: underline;
      transition: .4s;
      &:hover{
        text-decoration: none;
      }
    }
    &:nth-child(n+2){
      margin-top: 1em;
    }
  }
  &__list{
    margin-top: 1em;
    // list-style: disc; 
    li{
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 1em;
      &::before{
        content: '';
        position: absolute;
        top: 1em;
        left: 0;
        // color: $main_color;
        // font-size: .8em;
        transform: translate(0, -50%);
        width: .5em;
        height: .5em;
        background: $main_color;
        border-radius: 50%;
        display: inline-block;
        margin-right: .5em;
      }
    }
  }
  @include mq(lg){
    &__block{
      &:nth-child(n+2){
        margin-top: 4rem;
      }
    }
    &__ttl{
      span{
        &::after{
          width: 8rem;
        }
      }
    }
  }
  @include mq(sm){
    &__ttl{
      font-size:1.5em;
    }
  }
  @include mq(xs){

  }
  @include mq(ss){
    &__block{
      &:nth-child(n+2){
        margin-top: 3.2rem;
      }
    }
    &__ttl{
      font-size: 1.3125em;
      span{
        &::after{
          width: 6.4rem;
        }
      }
    }
  }
}
