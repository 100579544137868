@charset "utf-8";
/*============================================

  FLOW

============================================*/
/*-------------------------------
  FLOW > COMMON
-------------------------------*/
.p-flow{
  &__block{
    &:nth-child(n+2){
      margin-top: $margin_md*2;
    }
    @include mq(sm){
      &:nth-child(n+2){
        margin-top: $margin_lg;
      }

    }
  }
}

/*-------------------------------
  FLOW > LEADING
-------------------------------*/
.p-flow_leading{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &__img{
    position: relative;
    z-index: 2;
    width: 37.5%;
  }
  &__txt{
    position: relative;
    width: 62.5%;
    padding-top: $margin_ll;
    padding-left: $margin_md*2;
    z-index: 2;
    &:before,
    &:after{
      position: absolute;
    }
    &:before{
      font-size: 1.25em;
      color: map-get($color, 'sub2');
      @include icon('crowd03');
      z-index: 1;
      left: $margin_ss*2;
      top: $margin_sm*2;
      z-index: 1;
    }
    &:after{
      font-size: 1.875em;
      color: $sub_color;
      @include icon('crowd04');
      z-index: 2;
      left: -$margin_sm;
      top: $margin_sm;
      z-index: 2;
    }
    > p{
      margin-top: 1em;
    }
  }
  @include mq(md){
    &__txt{
      padding-top: $margin_md*2;
      padding-left: $margin_md;
      &:before{
        font-size: 1.125em;
        top: $margin_md;
      }
      &:after{
        font-size: 1.5em;
      }
    }
  }
  @include mq(sm){
    &__img{
      width: 35%;
    }
    &__txt{
      width: 60%;
      padding: 0;
      &:before,
      &:after{
        display: none;
      }
    }
  }
  @include mq(xs){
    display: block;
    &__img{
      width: 100%;
      text-align: center;
      @include mq(ss-){
        img{
          max-width: 60%;
        }
      }
    }
    &__txt{
      margin-top: $margin_sm;
      width: 100%;
    }
  }
}

/*-------------------------------
  FLOW > MAIN
-------------------------------*/
.p-flow_main{
  &__contact{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1.5em;
  }
  &__tel{
    margin-right: $margin_sm;
    em{
      color: $sub_color;
      font-weight: 600;
      font-style: normal;
      line-height: 1;
      display: block;
      margin-top: .5em;
    }
  }
  @include mq(ss){
    &__contact{
      display: block;
    }
    &__tel{
      text-align: center;
      margin-right: 0;
      margin-bottom: $margin_ss;
    }
  }
}

/*-------------------------------
  FLOW > ABOUT
-------------------------------*/
.p-flow_about{
  > p{
    margin-bottom: 5.25em;
  }
  &__list{
    position: relative;
    background: #fff;
    >*{
      position: relative;
      z-index: 2;
    }
    svg{
      position: absolute;
      z-index: 1;
      opacity: .2;
      right: $margin_ss;
      bottom: $margin_ss;
      width: 18.75em;
      height: 12.5em;
    }
  }
  @include mq(sm){
    > p{
      margin-bottom: 3.75em;
    }
    &__list{
      svg{
        right: $margin_ss;
        width: 12.6em;
        height: 8.2em;
      }
    }
  }
  @include mq(ss){
    &__list{
      svg{
        width: 9em;
        height: 6em;
        opacity: .1;
      }
    }
  }
}


/*-------------------------------
  FLOW > RESERVE
-------------------------------*/
.p-flow_reserve{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse;
  &__img{
    width: 58.3%;
  }
  &__txt{
    width: 41.7%;
    padding-right: $margin_sm*2;
    > p{
      margin-top: 1em;
    }
  }
  @include mq(md){
    &__img,
    &__txt{
      width: 50%;
    }
  }
  @include mq(xs){
    display: block;
    &__img{
      width: 100%;
      text-align: center;
      @include mq(ss-){
        > img{
          max-width: 50%;
        }
      }
    }
    &__txt{
      width: 100%;
      margin-top: $margin_sm;
      padding-right: 0;
    }
  }
}
