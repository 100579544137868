@charset "utf-8";
/*============================================

  HOME

============================================*/
/*-------------------------------
  HOME > COMMON
-------------------------------*/

/*-------------------------------
  HOME > NEWS
-------------------------------*/
.p-home_news{
  margin-top: 7em;
  &__cont{
    max-width: 1200px;
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  &__ttl{
    font-size: 1.625em;
    margin-bottom: 1.153em;
    line-height: 1.34;
    font-weight: 500;
  }
  &__ttlen{
    font-weight: 600;
    font-size: .875em;
    font-family: $base_font_en;
    display: flex;
    align-items: center;
    color: $main_color;
    text-align: right;
    margin-bottom: .5em;
    &:before{
      content: '';
      border-top: 1px solid #707070;
      width: 2.14em;
      margin-right: .7em;
    }
  }
  &__link{
    z-index: 2;
    position: relative;
    transition: .4s;
    &::before{
      content: url('../img/index/news_icon.png') ;
      margin-right: .625em;
      transition: 5s;
      vertical-align: bottom;
    }
    @include mq(sm-){
      &:hover{
        color: $main_color;
      }
      &:hover::before{
        color: $main_color;
        content: url('../img/index/news_icon_h.png') ;
      }
    }
  }
  &__right{
    width: 80%;
  }
  @include mq(lg){
    &__cont{
      max-width: 960px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }
  @include mq(md){
    margin-top: 5.6em;
    &__right{
      margin-left: 3em;
    }
  }
  @include mq(sm){
    &__right{
      width: 45em;
      margin-left: 2em;
    }
  }
  @include mq(xs){
    margin-top: 4em;
    &__cont{
      display: block;
      margin: 0 1em;
    }
    &__left{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__right{
      width: 100%;
      margin-left: 0;
    }
  }
  @include mq(screen){
    &__cont{
      @include animation('fadeUp');
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
  }
}
/*-------------------------------
  HOME > COMPANY
-------------------------------*/
.p-home_company{
  margin-top: 6.25em;
  position: relative;
  &::before{
    position: absolute;
    z-index: -1;
    content: '';
    top: 0;
    right: 0;
    width: 67%;
    height: 100%;
    background: $main_color;
  }
  &__cont{
    overflow: hidden;
    padding: 6.25em 0;
  }
  &__main{
    max-width: 1200px;
    width: 94%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
  }
  &__imgwrap{
    position: relative;
    width: 50%;
    height:31.25em
  }
  &__img{
    position: absolute;
    width: 50vw;
    top: 0;
    right: 0;
    text-align: right;
    padding-right: 6.25em;
    img{
      object-fit: cover;
      object-position: 0 100%;
      min-height: 31.25em;
      width: 100%;
    }
  }
  &__desc{
    width: 50%;
    margin-left: auto;
    padding-top: 3.75em;
  }
  &__ttl{
    margin-bottom: 2.5em;
  }
  &__txt{
    color: #fff;
    margin-bottom: 3.125em;
  }
  @include mq(lg){
    &__main{
      max-width: 960px;
    }
    &__img{
      padding-right: 5em;
    }
  }
  @include mq(md){
    margin-top: 5.6em;
    &__cont{
    padding: 5em 0;
    }
    &__img{
      padding-right: 4em;
    }
  }
  @include mq(sm){
    &__cont{
      padding: 4em 0;
      }
    &__main{
      width: 94%;
    }
  }
  @include mq(xs){
    margin-top: 4em;
    &::before{
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: $main_color;
    }
    &__main{
      display: block;
    }
    &__imgwrap{
      position: static;
      width: 100%;
      height: auto;
    }
    &__img{
      position: static;
      width: 100%;
      padding-right: 0;
      img{
        min-height: inherit;
      }
    }
    &__desc{
      width: 100%;
      padding-top: 3em;
    }
    &__ttl{
      margin-bottom: 2em;
      text-align: center;
    }
    &__txt{
      margin-bottom: 2.5em;
    }
    &__btn{
      text-align: center;
    }
  }
  @include mq(screen){
    @include animation('fadeUp');
    &.is-anime{
      @include animation('fadeUp', 'after');
    }
  }
}

/*-------------------------------
  HOME > SERVICE
-------------------------------*/
.p-home_service{
  margin-top: 6.25em;
  &__ttl{
    margin-bottom: 2.5em;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
  }
  &__item{
    width: 50%;
    overflow: hidden;
  }
  &__link{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    p{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      text-align: center;
      color: #fff;
      font-size: 1.5em;
    }
  }
  &__fil{
    position: relative;
    padding: 11.25em 0;
    width: 100%;
    height: 100%;
    background: url('../img/index/service01.jpg') no-repeat center top / cover;
    transition: .4s;
    &::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(8, 29, 59, 0.5);
      transition: .4s;
    }
    @include mq(sm-){
      &:hover{
        transform:scale(1.2,1.2);
      }
      &:hover::after{
        background: rgba(8, 29, 59, 0.25);
      }
    }
    &.-bk2{
      background: url('../img/index/service02.jpg') no-repeat center top / cover;
    }
    &.-bk3{
      background: url('../img/index/service03.jpg') no-repeat center top / cover;
    }
    &.-bk4{
      background: url('../img/index/service04.jpg') no-repeat center top / cover;
    }
  }
  @include mq(md){
    margin-top: 5.6em;
    &__fil{
      padding: 9em 0;
    }
  }
  @include mq(sm){
    &__item{
      p{
        font-size: 1.2em;
      }
    }
    &__fil{
      padding: 7.2em 0;
    }
  }
  @include mq(xs){
    margin-top: 4em;
    &__ttl{
      margin-bottom: 2em;
    }
    &__list{
      display: block;
    }
    &__item{
      width: 100%;
      &:nth-child(n+2){
        margin-top: 1px;
      }
    }
  }
  @include mq(ss){
    &__fil{
      padding: 5.76em 0;
    }
  }
  @include mq(screen){
    @include animation('fadeUp');
    &.is-anime{
      @include animation('fadeUp', 'after');
    }
  }
}


/*-------------------------------
  HOME > RECRUIT
-------------------------------*/
.p-home_recruit{
  margin: 6.25em 0;
  &__cont{
    overflow: hidden;
  }
  &__main{
    max-width: 1200px;
    width: 94%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
  }
  &__imgwrap{
    position: relative;
    width: 50%;
    height:31.25em
  }
  &__img{
    position: absolute;
    width: 50vw;
    top: 0;
    left: 0;
    text-align: right;
    padding-left: 6.25em;
    img{
      object-fit: cover;
      object-position: 0 100%;
      min-height: 31.25em;
      width: 100%;
    }
  }
  &__desc{
    width: 50%;
    margin-right: auto;
    padding-top: 3.75em;
  }
  &__ttl{
    margin-bottom: 2.5em;
  }
  &__txt{
    margin-bottom: 3.125em;
  }
  @include mq(lg){
    &__main{
      max-width: 960px;
    }
    &__img{
      padding-left: 5em;
    }
  }
  @include mq(md){
    margin-top: 5.6em;
    &__img{
      padding-left: 4em;
    }
  }
  @include mq(sm){
    &__main{
      width: 94%;
    }
  }
  @include mq(xs){
    margin-top: 4em;
    &::before{
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: $main_color;
    }
    &__main{
      flex-direction: column-reverse;

    }
    &__imgwrap{
      position: static;
      width: 100%;
      height: auto;
    }
    &__img{
      position: static;
      width: 100%;
      padding-left: 0;
      img{
        min-height: inherit;
      }
    }
    &__desc{
      width: 100%;
      padding-top: 3em;
    }
    &__ttl{
      margin-bottom: 2em;
      text-align: center;
    }
    &__txt{
      margin-bottom: 2.5em;
    }
    &__btn{
      text-align: center;
    }
  }
  @include mq(screen){
    @include animation('fadeUp');
    &.is-anime{
      @include animation('fadeUp', 'after');
    }
  }
}

