@charset "utf-8";
/*============================================

  FAQ

============================================*/
/*-------------------------------
  FAQ > COMMON
-------------------------------*/
.p-faq{
  &__block{
    &:nth-child(n+2){
      margin-top: $margin_ll;
    }
    @include mq(sm){
      &:nth-child(n+2){
        margin-top: $margin_lg;
      }
    }
  }
}
