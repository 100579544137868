@charset "utf-8";
/*============================================

  HEADER

============================================*/
.l-header{
  $amp: &;
  &__inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 94%;
    margin: 0 2.5em;
  }
  &__left{
    padding: 1.875em 0;
    img{
      width: 15em;
    }
  }
  &__right{
    display: flex;
    align-items: center;
  }

  &__nav{
    display: flex;
    font-size: .875em;
    align-items: center;
  }
  &__link{
    line-height: 1;
    &:nth-child(n+2){
      margin-left: 1.875em;
    }
    @include mq(sm-){
      &.-hidden{
        display: none;
      }
    }
  }
  &__tel{
    margin-left: 3em;
    text-align: center;
    line-height: 1;
    span{
      font-size: 1.5em;
      color: $main_color;
      font-family: $base_font_en;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before{
        @include icon('phone-fill_01');
        font-size: .75em;
        margin-right: .417em;
        vertical-align: middle;
      }
    }
    p{
      font-size: .75em;
      margin-top: .625em;
    }
    @include mq(sm-){
      pointer-events: none;
    }
  }
  &__mail{
    width: 15em;
  }
  &__btn{
    display: inline-block;
    width: 100%;
    padding: .625em 0;
    text-align: center;
    background: $main_color;
    color: #fff;
    transition: .4s;
    span{
      font-size: .875em;
      &:before{
        @include icon('email-thin_01');
        font-size: 1.42em;
        margin-right: .71em;
        vertical-align: middle;
      }
    }
  @include mq(sm-){
    margin-left: 2em;
    &:hover{
      background-color: #fff;
      color: $main_color;
      border: 1px solid $main_color;
    }
  }
 }
  //  &__btn{
  //   display: inline-block;
  //   width: 100%;
  //  }
 &__hover{
  z-index: 2;
  position: relative;
  display: block;
  &:before{
    z-index: 1;
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    background-color: #241e1c;
    content: "";
    transition: all .25s cubic-bezier(1,0,0,1);
    transition-timing-function: cubic-bezier(1,0,0,1);
  }
  @include mq(md-){
    &:hover{
      color: $main_color;
    }
    &:hover::before{
      transform: scaleX(1);
      background-color: #006ff0;
    }
  }
 }
 &__hamburger{
  position: relative;
  width: 6rem;
  height: 6rem;
  background: $main_color;
  span{
    position: absolute;
    width: 60%;
    height: 2px;
    background: #fff;
    left: 20%;
    top: calc(50% - 1px);
    transition: all .6s;
    &::before{
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
      transform: translateY(-0.75em);
    }
    &::after{
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
      transform: translateY(0.75em);
    }
  }
  .slideout-open &{
    span{
      background: transparent;
      &:before{
        transform:rotate(45deg);
      }
      &:after{
        transform:rotate(-45deg);
      }
    }
  }
  @include mq(sm-){
    display: none;
  }
 }
 @include mq(md){
  &__left{
    padding: 1.5em 0;
  }
 }
 @include mq(sm){
  &__inner{
    margin: 0 2em;
  }
  &__right{
    width: 45%;
    height: 100%;
    margin-left: auto;
    display: block;
    background: $main_color;
    padding: 11rem 0 3rem;
  }
  &__menu{
    margin-right: 1em;
  }
  &__nav{
    width: 90%;
    margin: 0 auto;
    display: block;
    // padding: 11rem 0 3rem;
    font-size: 1.25em;
    overflow: hidden;
    color: #fff;
  }
  &__link{
    padding: 1em .25em;
    border-bottom: 1px solid #fff;
    &:nth-child(n+2){
      margin-left: 0em;
    }
  }
  &__tel{
    margin: 1em auto;
    padding: 0 3rem ;
    text-align: center;
    line-height: 1;
    color: #fff;
    span{
      font-size: 2em;
      color: #fff;
    }
    p{
      font-size: 1em;
      margin-top: .625em;
    }
  }
  &__mail{
    width: 90%;
    margin: 1em auto;
    background: #fff;
    color: $main_color;
    transition: .4s;
    span{
      font-size: 1.25em;
    }
  }
 }
 @include mq(xs){
  &__inner{
    margin: 0 1em;
  }
  &__right{
    width: 100%;
    height: 100%;
  }
 }
 @include mq(ss){
  &__left{
    padding: 1.2em  0;
    img{
      width: 12em;
    }
  }
  &__right{
    width: 100%;
    height: auto;
  }
  &__hamburger{
    width: 5rem;
    height: 5rem;
  }
 }
}

