@charset "utf-8";
/*============================================

  LINK PRIMARY

============================================*/
.c-link_pr{
  color: $sub_color;
  text-decoration: underline;
  @include mq(sm-){
    &:hover{
      text-decoration: none;
      color: $accent_color;
    }
  }
}


/*============================================

  LINK ARROW

============================================*/
.c-link_ar{
  position: relative;
  padding-left: 1em;
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  &:before{
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    color: $main_color;
    transform: translateY(-55%);
    @include icon('arrow-right');
    transition:inherit;
  }
  &.-blk{
    &:before{
      color: $font_color;
    }
  }
  @include mq(sm-){
    &:hover{
      opacity: .5;
      &:before{
        left: .25em;
      }
    }
  }
}
