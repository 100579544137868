@charset "utf-8";
/*============================================

  IMAGE

============================================*/
/*-------------------------------
  CASE > OBJECT FIT
-------------------------------*/
.c-ofi{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  &__img{
    position: absolute;
    left: 0;
    top: 0;
    max-width: none;
    max-height: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover;object-position:center center;';
    &.-contain{
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;';
    }
  }
}

/*-------------------------------
  CASE > GALLERY
-------------------------------*/
.c-gallery{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  > a{
    position: relative;
    display: block;
    background: #fff;
    img{
      transition:$transition_val;
    }
    @include mq(xs-){
      width: 18%;
      padding-top: 15%;
      margin-right: 2.5%;
      &:nth-child(5n){
        margin-right: 0;
      }
      &:nth-child(n+6){
        margin-top: 2.5%;
      }
    }
    @include mq(sm-){
      &:hover{
        img{
          opacity: .5;
        }
      }
    }
  }
  @include mq(xs){
    @include mq(ss-){
      > a{
        width: 23.5%;
        padding-top: 20%;
        margin-right: 2%;
        &:nth-child(4n){
          margin-right: 0;
        }
        &:nth-child(n+5){
          margin-top: 2%;
        }
      }
    }
  }
  @include mq(ss){
    > a{
      width: 48%;
      padding-top: 32%;
      margin-right: 4%;
      &:nth-child(even){
        margin-right: 0;
      }
      &:nth-child(n+3){
        margin-top: 4%;
      }
    }
  }
}
