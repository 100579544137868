@charset "utf-8";
/*============================================

 RECRUIT

============================================*/
/*-------------------------------
  RECRUIT > COMMON
-------------------------------*/
.p-recruit{
  &__cont{
    text-align: center;
  }
  &__ttl{
    margin-bottom: 3em;
  }
  &__block{
    &:nth-child(n+2){
      margin-top: 6em;
    }
  }
  &__item{
    &:nth-child(n+2){
      margin-top: 6em;
    }
  }
  &__head{
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 1em;
  }
  &__tab{
    margin-bottom: 3em;
  }
  &__btn{
    margin-top: 4em;
  }
  @include mq(sm){
    &__tab{
      margin-bottom: 2em;
    }
    &__btn{
      margin-top: 3em;
    }
  }
  @include mq(xs){
    &__block{
      &:nth-child(n+2){
        margin-top: 4.8em;
      }
    }
    &__item{
      &:nth-child(n+2){
        margin-top: 4.8em;
      }
    }
    &__ttl{
      margin-bottom: 1.5em;      
    }
  }
  @include mq(screen){
    &__item{
      @include animation('fadeUp');
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
    &__btn{
      @include animation('fadeUp');
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
  }
}


