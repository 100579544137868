@charset "utf-8";
/*============================================

  TITLE

============================================*/
/*-------------------------------
  TITLE PRIMARY
-------------------------------*/
.c-ttl_pr{
  font-weight: 500;
  line-height: 1;
  color: #fff;
  >*{
    display: block;
  }
  em{
    font-size: .875em;
    font-family: $base_font_en;
    margin-bottom: 1em;
  }
  span{
    font-size: 2.5em;
  }
  &.-bk{
    color: $font_color;
  }
  &.-cnt{
    text-align: center;
  }
  @include mq(sm){
    span{
      font-size: 2em;
    }
  }
  @include mq(ss){
    span{
      font-size: 1.75em;
    }
  }
}

/*-------------------------------
  TITLE SUB
-------------------------------*/
.c-ttl_sub{
  font-weight: 700;
  line-height: 1;
  >*{
    display: block;
  }
  em{
    font-size: .875em;
    font-family: $base_font_en;
    margin-bottom: .5em;
  }
  span{
    font-size: 1.75em;
    display: flex;
    flex-direction: column;
    &::after{
      content: '';
      border-top: 1px solid $font_color;
      width: 10rem;
      height: 1px;
      margin-top: .625em;
    }
  }
  &.-cnt{
    text-align: center;
  }
  @include mq(lg){
    span{
      &::after{
        width: 8rem;
      }
    }
  }
  @include mq(sm){
    span{
      font-size: 1.5em;
    }
  }
  @include mq(ss){
    span{
      font-size: 1.3125em;
      &::after{
        width: 6.4rem;
      }
    }
  }
}

/*-------------------------------
  TITLE MID
-------------------------------*/
.c-ttl_md{
  display: inline-block;
  line-height: 1;
  font-weight: 500;
  em{
    font-size: .875em;
    font-family: $base_font_en;
    margin-bottom: 1em;
    line-height: 1.875;
  }
  span{
    font-size: 2.25em;
    display: flex;
    flex-direction: column;
    &::after{
      content: '';     
      border-top: 1px solid $font_color;
      width: 100%;
      height: 1px;
      margin: .5em auto 0;
      padding: 0 10px;
    }
  }
  &.-cnt{
    text-align: center;
  }
  @include mq(sm){
    span{
      font-size: 1.8em;
    }
  }
  @include mq(ss){
    span{
      font-size: 1.575em;
    }
  }
}


/*-------------------------------
  TITLE SMALL
-------------------------------*/
// .c-ttl_sm{
//   display: inline-block;
//   line-height: 1;
//   font-weight: 500;
//   span{
//     font-size: 2.25em;
//     display: flex;
//     flex-direction: column;
//     &::after{
//       content: '';     
//       border-top: 1px solid $font_color;
//       width: 100%;
//       height: 1px;
//       margin: .5em auto 0;
//       padding: 0 10px;
//     }
//   }
//   &.-cnt{
//     text-align: center;
//   }
//   @include mq(sm){
//     span{
//       font-size: 1.8em;
//     }
//   }
//   @include mq(ss){
//     span{
//       font-size: 1.575em;
//     }
//   }
// }

