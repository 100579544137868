@charset "utf-8";
/*============================================

  BREADCRUMB

============================================*/
.l-crumb{
  line-height: 1;
  padding: 0.625em 0;
  border-bottom:solid 1px $sub_color;
  &__list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .875em;
    li{
      position: relative;
      &:after{
        display: inline-block;
        vertical-align: middle;
        @include icon('arrow-fill_20');
        padding: 0 .5em;
        font-size: .857em;
      }
      a{
        position: relative;
        display: inline-block;
        padding:5px 0;
        max-width: 30em;
        text-overflow:ellipsis;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: middle;
        &:hover{
          color: $main_color;
          text-decoration:underline;
        }
      }
      &:last-child{
        padding-right:0;
        margin-right: 0;
        &:after{
          display: none;
        }
        a{
          pointer-events: none;
        }
      }
    }
  }
  @include mq(xs){
    display: none;
  }
}
