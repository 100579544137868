@charset "utf-8";
/*============================================

  SERVICE

============================================*/
/*-------------------------------
  SERVICE > COMMON
-------------------------------*/
.p-service{
  &__block{
    &:nth-child(n+2){
      margin-top: 5em;
    }
  }
  &__cont{
    position: relative;
    padding-bottom:5em ;
  }
  &__main{
    display: flex;
  }
  &__desc{
    padding: 3.125em;
    min-height: 46rem;
    border: 1px solid $sub_color;
    background: #fff;
    width: 50%;
    position: relative;
    z-index: 2;
    &.-odd{
      margin-right: auto;
    }
    &.-even{
      margin-left: auto;
    }
  }
  // &__ttl{
  //   span{
  //     display: flex;
  //     flex-direction: column;
  //     &::after{
  //       content: '';
  //       border-top: 1px solid $font_color;
  //       width: 10rem;
  //       height: 1px;
  //       margin-top: .625em;
  //     }
  //   }
  // }
  &__txt{
    margin-top: 1.875em;
  }
  &__list{
    margin-top: 1.875em;
    color: $main_color;
    li{
      display: flex;
      align-items: center;
      &::before{
        content: '';
        width: .5em;
        height: .5em;
        background: $main_color;
        border-radius: 50%;
        display: inline-block;
        margin-right: .5em;
      }
    }
  }
  &__imgwrap{
    position: absolute;
    width: 60%;
    height: 50rem;
    bottom: 0;
    z-index: 1;
    &.-odd{
      right: 0;
    }
    &.-even{
      left: 0;
    }
  }
  &__img{
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    left: 0;
    top: 0;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
  @include mq(lg){
    &__desc{
      min-height: 42rem;
    }
    // &__ttl{
    //   span{
    //     &::after{
    //       width: 8rem;
    //     }
    //   }
    // }
    &__imgwrap{
      height: 45rem;
    }
  }
  @include mq(md){
    &__block{
      &:nth-child(n+2){
        margin-top: 4em;
      }
    }
    &__cont{
      position: relative;
      padding-bottom: 4em ;
    }
    &__txt{
      margin-top: 1.5em;
    }
    &__list{
      margin-top: 1.5em;
    }
  }
  @include mq(sm){
    &__block{
      &:nth-child(n+2){
        margin-top: 6em;
      }
    }
    &__cont{
      padding-bottom: 0 ;
    }
    &__main{
      justify-content: space-between;
      &.-even{
        flex-direction: row-reverse;
      }
    }
    &__desc{
      padding: 2.5em;
      min-height: auto;
      width: 50%;
      position: static;
      &.-odd{
        margin-right: 0;
      }
      &.-even{
        margin-left: 0;
      }
    }
    &__imgwrap{
      position: static;
      width: 50%;
      height: auto;
    }
    &__img{
      position: static;
      img{
        position: static;
      }
    }
  }
  @include mq(xs){
    &__block{
      &:nth-child(n+2){
        margin-top: 4em;
      }
    }
    &__cont{
      position: static;
      padding-bottom: 0em ;
    }
    &__main{
      flex-direction: column-reverse;
      &.-even{
        flex-direction: column-reverse;
      }
    }
    &__desc{
      padding: 2.5em;
      width: 100%;
    }
    &__txt{
      margin-top: 1.25em;
    }
    &__list{
      margin-top: 1.25em;
    }
    &__imgwrap{
      width: 100%;
    }
  }
  @include mq(ss){
    &__desc{
      padding: 2em 1em;
    }
    // &__ttl{
    //   span{
    //     &::after{
    //       width: 6.4rem;
    //     }
    //   }
    // }
  }
  @include mq(screen){
    &__block{
      @include animation('fadeUp');
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
  }
}

