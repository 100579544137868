@charset "utf-8";
/*============================================

  OTHER COMPONENT

============================================*/

/*-------------------------------
  FREEDIAL
-------------------------------*/
.c-freedial{
  line-height: 1;
  > span{
    display: block;
    font-size: .875em;
    font-weight: 600;
    margin-bottom: .5em;
  }
  > a{
    position: relative;
    font-size: 2em;
    padding-left: 1.25em;
    &:before{
      position: absolute;
      left: 0;
      top: 50%;
      transform:translateY(-50%);
      @include icon('free');
      color : #37A634;
    }
    @include mq(sm-){
      pointer-events: none;
    }
  }
}

/*-------------------------------
  INQUIRY
-------------------------------*/
.c-inquiry{
  display: block;
  line-height: 1;
  background: $sub_color;
  color: #fff;
  text-align: center;
  padding: .9em 2em;
  font-size: 1.125em;
  border:solid 1px $sub_color;
  @include mq(md-){
    min-width: 290px;
  }
  span{
    position: relative;
    padding-left: 1.25em;
    font-weight: 600;
    &:before{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @include icon('mail1');
    }
  }
  @include mq(sm-){
    &:hover{
      background: #fff;
      color: $sub_color;
    }
  }
}
