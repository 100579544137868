@charset "utf-8";

/*-------------------------------
  WP > EDITOR
-------------------------------*/
.wp-editor {
  +.c-btnwrap{
    margin-top: $margin_md;
  }
  strong { 
    font-weight: 700; 
  }
  em {
    font-style: italic; 
  }
  blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }
  img.alignleft {
    margin: 0 auto 0 0;
  }
  img.aligncenter {
    display: block;
    margin: 0 auto;
  }
  img.alignright {
    display: block;
    margin: 0 0 0 auto;
  }
  img[class*="wp-image-"],
  img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
  }
  hr{
    margin: 2em 0;
  }
  *{
    & + p{
      margin-top: 1em;
    }
    & + ul,
    & + ol,
    & + .c-tbl_responsive {
      margin-top: 2em;
    }

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 1.5em;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: .1em;
  }
  h2{
    position: relative;
    font-size: 1.75em;
    padding-top: .25em;
    padding-left: .5em;
    &:before{
      position: absolute;
      content:"";
      left: 0;
      top: 0;
      width: 1.25em;
      height: 1.25em;
      background: #f3f3f3;
      z-index: -1;
    }
  }
  h3{
    position: relative;
    font-size: 1.5em;
    padding-left: 2.25em;
    &:before,
    &:after{
      position: absolute;
      content:"";
      left: 0;
      top: .6em;
      background: $font_color;
    }
    &:before{
      width: 1.75em;
      height: 1px;
    }
    &:after{
      width: .35em;
      height: .35em;
      transform:translateY(-50%);
      border-radius: 50%;
    }
  }
  h4{
    font-size: 1.25em;
  }

  a{
    color: $link_color;
    word-break: break-all;
    &:focus{
      opacity: .7;
    }
    @include mq(sm-){
      &:hover{
        opacity: .7;
      }
    }
  }

  ul,
  ol {
    padding-left: 2em;
    line-height: 1.5;
    > li:not(:first-child){
      margin-top: .6em;
    }
  }
  
  ul{
    > li{
      list-style-type: disc;
    }
  }
  ol{
    >li{
      list-style-type: decimal;
    }
  }
  
  table{
    tr{
      > th,
      > td{
        padding: 1em;
        border: 1px solid #ddd;
      }
      > th{
        background: #eee;
      }
    }
  }

  @include mq(sm){
    *{
      & + p,
      & + ul,
      & + ol,
      & + .c-tbl_responsive {
        margin-top: 1.2em;
      }

      & + h1,
      & + h2,
      & + h3,
      & + h4,
      & + h5,
      & + h6 {
        margin-top: 2.5em;
      }
    }
    h3{
      border-bottom:solid 1px $font_color;
      padding-left: .4em;
      padding-bottom: .25em;
      &:before{
        display: none;
      }
      &:after{
        top: initial;
        bottom:0;
        transform:translateY(50%);
      }
    }
    ul,
    ol {
      > li:not(:first-child){
        margin-top: .4em;
      }
    }
  }
}
/*-------------------------------
  WP > PAGINATION
-------------------------------*/
.wp-pagenavi{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  >*{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:0.5em ;
  }
  .current{
    background: $font_color;
    color:#fff;
    pointer-events: none;
    width: 2em;
    height: 2em;
  }
  .pages{
    margin-right: 1em;
  }
  a{
    width: 2em;
    height: 2em;
    border:solid 1px $font_color;
    @include mq(sm-){
      &:hover{
        background: $font_color;
        color: #fff;
      }
    }
    &.previouspostslink{
      position: relative;
      padding-left: 0em;
      margin-right: 0.5em;
      // &:before,
      // &:after {
      //   content:"";
      //   left: 0;
      // }
      // &:before{
      //   transform-origin: left bottom;
      //   transform:rotate(-45deg);
      // }
    }
    &.nextpostslink{
      position: relative;
      padding-right: 0em;
      margin-left: 0.5em;
      // &:before,
      // &:after {
      //   content:"";
      //   right: 0;
      // }
      // &:before{
      //   transform-origin: right bottom;
      //   transform:rotate(45deg);
      // }
    }
    &:before,
    &:after {
      position: absolute;
      background: $font_color;
      top:.5em;
    }
    &:before{
      width: .45em;
      height: .5px;
    }
    &:after {
      width: 1.25em;
      height: 1px;
    }
    @include mq(sm-){
      &:hover{
        font-weight: 700;
      }
    }
  }
  @include mq(ss){
    a{
      &.previouspostslink{
        margin-right: 1em;
      }
      &.nextpostslink{
        margin-left: 1em;
      }
    }
    .extend{
      display: none;
    }
  }
}