@charset "utf-8";
/*============================================

  ETC

============================================*/
/*-------------------------------
  404
-------------------------------*/
.p-404{
  text-align: center;
  &__btn{
    margin-top: 1em;
  }

}