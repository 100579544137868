@charset "utf-8";
/*============================================

  LIST>NEWS

============================================*/
.c-tab_news{
  display: flex;
  &__item{
    width: 25%;
    margin-top: -1px;
    margin-left: -1px;
  }
  &__link{
    display: inline-block;
    background: #fff;
    border:solid 1px #000;
    color: #000;
    padding: 1em;
    width: 100%;
    text-align: center;
    line-height: 1.5;
    transition: .4s;
    @include mq(sm-){
      &:hover{
        background: #000;
        color: #fff;
      }
    }
  }
  @include mq(ss){
    flex-wrap: wrap;
    &__item{
      width: 50%;
    }
  }
}

/*============================================

  LIST>RECRUIT

============================================*/
.c-tab_recruit{
  display: flex;
  &__item{
    width: calc(100% / 3);
    margin-top: -1px;
    margin-left: -1px;
  }
  &__link{
    display: inline-block;
    background: #fff;
    border:solid 1px #000;
    color: #000;
    padding: 1em;
    width: 100%;
    text-align: center;
    line-height: 1.5;
    transition: .4s;
    &.click{
      background: #000;
      color: #fff;
    }
  }
  @include mq(ss){
    flex-wrap: wrap;
    &__item{
      width: 50%;
    }
  }
}