@charset "utf-8";
/*============================================

  MODAL WINDOW

============================================*/
.c-modal{
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  &__bg{
    position: absolute;
    background: rgba(0,0,0,.7);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &__box{
    position: absolute;
    max-width: $base_width;
    background: #fff;
    width: 94%;
    max-height: 94%;
    overflow-y: auto;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    z-index: 2;
    &::-webkit-scrollbar {
      width: .6rem;
    }
    &::-webkit-scrollbar-track {
      background: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background: $accent_color;
    }
  }
}