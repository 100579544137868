@charset "utf-8";
/*============================================

  FORM

============================================*/
.c-form{
  $amp: &;
  &__sheet{
    &:not(:last-child){
      margin-bottom: $margin_md;
    }
    >tbody{
      >tr{
        @include mq(xs-){
          border-bottom:solid 1px #e5e5e5;
        }
        >th,
        >td{
          text-align: left;
          line-height: 1.5;
          padding: 2em 1em;
          vertical-align: top;
        }
        >th{
          width: 30%;
        }
        >td{
          width: 70%;
        }
      }
    }
    @include mq(xs){
      >tbody{
        >tr{
          display: block;
          padding-bottom: 1.125em;
          >th,
          >td{
            display: block;
            padding: 0;
          }
          >th{
            width: 100%;
            font-size: 1.125em;
            margin-bottom: .75em
          }
          >td{
            width: 100%;
          }
          &:nth-last-child(n+2){
            margin-bottom: 1.125em;
          }
        }
      }
    }
  }
  &__ttl{
    display: inline-block;
    padding-right: .75em;
    font-weight: inherit;
    &.-required{
      position: relative;
      &:after{
        position: absolute;
        content:"*";
        right: 0;
        top: 0;
        font-size: 1.125em;
        color:#c11;
      }
    }
  }
  &__row{
    
  }
  &__rowgroup{
    tr{
      &:first-child{
        th,
        td{
          padding-top: 0;
        }
      }
      &:last-child{
        th,
        td{
          border-bottom:none;
        }
      }
    }
    th,
    td{
      padding-bottom: .75em;
      padding-top: .75em;
    }
    th{
      white-space: nowrap;
      font-weight: 400;
      padding-right: 2em;
    }
  }
  &__field{
    &:nth-child(n+2){
      margin-top: 1em;
    }
    .error{
      display: block;
      color:#c11;
      font-size: .875em;
      margin-top: .5em;
    }
    &.-postal{
      display: flex;
      align-items: center;
      em{
        margin-right: 0.5em;
        font-style: normal;
        display: block;
      }
    }
  }
  &__error{
    color: #c11;
    font-size: .875em;
    margin-top: .5em;
  }
  &__notes{
    position: relative;
    margin-top: .5em;
    font-size: .875em;
    color:$font_color;
    opacity: .75;
    padding-left: 1em;
    &:before{
      position: absolute;
      content:"※";
      left: 0;
      top: 0;
    }
  }

  &__input{
    &.-text,
    &.-textarea{
      display: inline-block;
      vertical-align: middle;
      padding: .75em;
      background: #f5f5f5;
      width: 100%;
      @include mq(xs-){
        &.-short{
          width: 40%;
        }
        &.-middle{
          width: 60%;
        }
        &.-long{
          width: 80%;
        }
      }
      &.-half{
        width: calc((100% - 2em) / 2);
        &:nth-child(n+2){
          margin-left: 1em;
        }
      }
      &.-full{
        width: 100%;
      }
    }
    &.-textarea{
      min-height:10.5em;
      line-height: 1.5;
    }
  }
  &__select{
    background: #f5f5f5;
    display: inline-block;
    max-width: 100%;
    padding: 1em 1.25em;
    line-height: 1;
    @include mq(xs-){
      &.-short{
        width: 40%;
      }
    }
  }

  &__check,
  &__radio{
    + *{
      position: relative;
      display: inline-block;
      padding: .25em 0 .25em 2.25em;
      line-height: 1;
    }
  }
  &__radio{
    + *{
      &:before,
      &:after{
        position: absolute;
        content:"";
        left: 0;
        top: 0;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        background: #f5f5f5;
        box-shadow: 0 0 1px rgba(0,0,0,.15);
      }
      &:after{
        background: $main_color;
        transform: scale(.4);
        visibility: hidden;
      }
    }
    &:checked{
      + *{
        &:after{
          visibility: visible;
        }
      }
    }
  }
  &__check{
    + *{
      &:before{
        position: absolute;
        content:"";
        left: 0;
        top: 0;
        border-radius: 3px;
        width: 1.5em;
        height: 1.5em;
        background: #f5f5f5;
      }
      &:after{
        position: absolute;
        content:"";
        left: 0;
        width: 1em;
        height: .65em;
        transform:rotate(-45deg) translate(0,50%);
        top: 0;
        border-left:solid 3px $main_color;
        border-bottom:solid 3px $main_color;
        visibility: hidden;
      }
    }
    &:checked{
      + *{
        &:after{
          visibility: visible;
        }
      }
    }
  }
  &__agreement{
    margin-bottom: 1.5em;
    a{
      text-decoration: underline;
      @include mq(sm-){
        &:hover{
          text-decoration: none;
        }
      }
    }
    .error{
      color:#c11;
    }
  }
  &__btnwrap{
    @include mq(xs-){
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__btn{
    cursor: pointer;
    display: block;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    padding: 1.5em 1em;
    text-align: center;
    border: solid 1px $main_color;
    background: $main_color;
    transition:$transition_val;
    &.-back{
      background: #aaa;
      border-color: #aaa;
    }
    @include mq(sm-){
      &:hover{
        background: #fff;
        color: $main_color;
      }
      &.-back{
        &:hover{
          background: #fff;
          color: #aaa;
        }
      }
    }
    @include mq(xs-){
      width: 35%;
      &:nth-child(n+2){
        margin-left: 2em;
      }
    }
    @include mq(xs){
      width: 50%;
      margin:0 auto;
      &:nth-child(n+2){
        margin-top: 1.5em;
      }
    }
    @include mq(ss){
      width: 100%;
    }
  }
  &.-confirm{
    #{$amp}__notes{
      display: none;
    }
    #{$amp}__error{
      display: none;
    }
    #{$amp}__agreement{
      display: none;
    }
  }
  &.-thanks{
    p{
      margin-bottom:1.5em;
      line-height: 2;
      @include mq(xs-){
        text-align: center;
      }
    }
  }
}