@charset "utf-8";
/*============================================

  PANEL

============================================*/
/*-------------------------------
  PANEL > CONTAINER
-------------------------------*/
.p-panel_container{

}
/*-------------------------------
  PANEL > OVERLAY IMAGE
-------------------------------*/
.p-panel_ov{
  $amp: &;
  position: relative;
  padding-bottom: $margin_sm*2;
  & + &{
    margin-top: $margin_lg;
  }
  &__txt{
    > p{
      margin-bottom: 1.875em;
    }
  }
  @include mq(xs-){
    &__img{
      position: absolute;
      width: 58.3%;
      height: calc(100% - #{$margin_sm*2});
      top: $margin_sm*2;
      z-index: 2;
    }
    &__txt{
      position: relative;
      width: 41.7%;
      padding: $margin_ss*4 $margin_sm*2;
      z-index: 1;
      > *{
        position: relative;
        z-index: 2;
      }
      &:before{
        position: absolute;
        content: "";
        width: 200%;
        height: 100%;
        top: 0;
        background: url('../img/common/bg_paper.jpg');
      }
    }
    &:nth-child(odd){
      #{$amp}__img{
        left: 0;
      }
      #{$amp}__txt{
        margin-left: auto;
        &:before{
          right:0;
        }
      }
    }
    &:nth-child(even){
      #{$amp}__img{
        right: 0;
      }
      #{$amp}__txt{
        margin-right: auto;
        &:before{
          left: 0;
        }
      }
    }
    @include mq(sm){
      &__img{
        width: 50%;
      }
      &__txt{
        width: 50%;
        padding: $margin_sm*2 $margin_ss*2;
        &:before{
          width: 150%;
        }
      }
    }
  }
  @include mq(xs){
    padding-bottom: 0;
    & + &{
      margin-top: $margin_lg;
    }
    &__img{
      position: relative;
      padding-top: 26.4%;
    }
    @include mq(ss-){
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &__img{
        width: 40%;
      }
      &__txt{
        width: 55%;;
      }
      &:nth-child(even){
        flex-direction: row-reverse;
      }
    }
  }
  @include mq(ss){
    &__img{
      padding-top: 66.6%;
      margin-bottom: $margin_ss;
    }
  }
}

/*-------------------------------
  PANEL > BACKGROUND
-------------------------------*/
.p-panel_bg{
  position: relative;
  background: url('../img/common/bg_paper.jpg') repeat center center;
  padding: $margin_md;
  margin-top: 1.125em;
  &__ttl{
    position: absolute;
    top: 0;
    left: $margin_md;
    transform:translateY(-50%);
    font-size: 2.25em;
    line-height: 1;
    font-weight: 400;
    &.-sm{
      font-size: 1.875em;
    }
  }
  &__desc{
    margin-top: 1em;
  }
  @include mq(sm){
    &__ttl{
      font-size: 2em;
      &.-sm{
        font-size: 1.5em;
      }
    }
  }
  @include mq(xs){
    padding: $margin_sm $margin_ss;
    &__ttl{
      left: $margin_sm;
    }
  }
  @include mq(ss){
    &__ttl{
      font-size: 1.75em;
      left: $margin_ss;
      &.-sm{
        font-size: 1.375em;
      }
    }
  }
}
/*-------------------------------
  PANEL > FLOW
-------------------------------*/
.p-panel_flow{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  counter-reset: count1 0;
  &__ttl{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    counter-increment: count1 1;
    width: 20rem;
    color: $main_color;
    line-height: 1;
    font-size: 1.5em;
    font-weight: 400;
    &:before{
      content: counter(count1, decimal-leading-zero);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.3em;
      height: 3.3em;
      font-size: 1.25em;
      line-height: 1;
      color: #fff;
      background: $main_color;
    }
    > h3{
      margin-top: .8em;
      font-weight: 400;
    }
  }
  &__cont{
    padding: $margin_md;
    border-top:solid 1px map-get($color, 'sub2');
    width: calc(100% - 20rem);
  }
  @include mq(xs-){
    &__ttl,
    &__cont{
      &:nth-of-type(n+2){
        margin-top: $margin_md;
      }
    }
  }
  @include mq(md){
    &__cont{
      padding: $margin_sm;
    }
  }
  @include mq(sm){
    &__ttl{
      width: 15rem;
    }
    &__cont{
      width: calc(100% - 15rem);
    }
  }
  @include mq(xs){
    display: block;
    &__ttl{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      width: 100%;
      &:before{
        width: 2.5em;
        height: 2.5em;
        font-size: 1.125em;
      }
      h3{
        margin-top: 0;
        margin-left: 1em;
      }
    }
    &__cont{
      width: 100%;
      border-top: none;
      border-bottom:solid 1px map-get($color, 'sub2');
      padding: $margin_sm 0;
    }
    &__cont{
      &:not(:last-child){
        margin-bottom: $margin_sm;
      }
    }
  }
  @include mq(ss){
    &__ttl{
      &:before{
        width: 2em;
        height: 2em;
      }
    }
  }
}
