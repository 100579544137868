@charset "utf-8";
.lb-cancel {
  background-image: url(../img/plugins/lightbox/loading.gif);
}

.lb-nav a.lb-prev {
  background-image: url(../img/plugins/lightbox/prev.png);
}

.lb-nav a.lb-next {
  background-image: url(../img/plugins/lightbox/next.png);
}

.lb-data .lb-close {
  background-image: url(../img/plugins/lightbox/close.png);
}
