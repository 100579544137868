@charset "utf-8";
/*============================================

  CONTENT

============================================*/
.l-content{

}
.l-main{
  padding: 14rem 0 10rem;
  @include mq(lg){
   padding: 11.2rem 0 8rem;
  }
  @include mq(sm){
    padding: 10rem 0 8rem;
  }
  @include mq(ss){
    padding: 7rem 0 7rem;
  }

}
