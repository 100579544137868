@charset "utf-8";
/*============================================

  SITEMAP

============================================*/
/*-------------------------------
  SITEMAP > COMMON
-------------------------------*/
.p-sitemap{
  &__cont{
    display: flex;
    flex-wrap: wrap;
  }
  &__btn{
    width: 31%;
    margin-right: 3.5%;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(n+4){
      margin-top: 3.5%;
    }
  }
  @include mq(xs){
    &__btn{
      width: 48%;
      margin-right: 4%;
      &:nth-child(3n){
        margin-right: 4%;
      }
      &:nth-child(2n){
        margin-right: 0;
      }
      &:nth-child(n+3){
        margin-top: 4%;
      }
    }
  }
  @include mq(ss){
    &__btn{
      width: 100%;
      margin-right: 0;
      &:nth-child(2n){
        margin-right: 0;
      }
      &:nth-child(3n){
        margin-right: 0;
      }
      &:nth-child(n+2){
        margin-top: 6%;
      }
    }
  }
  @include mq(screen){
    &__block{
      @include animation('fadeUp');
      &.is-anime{
        @include animation('fadeUp', 'after');
      }
    }
  }
}
