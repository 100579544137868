@charset "utf-8";
/*============================================

  GLOBAL NAVIGATION

============================================*/
.p-gnavi{
  $amp: &;
  // background: $main_color;
  line-height: 1;
  padding: 1.25em 0;
  &__list{
    > li{
      @include mq(sm-){
        width: calc(100% / 6);
        text-align: center;
        border-right:solid 1px #fff;
      }
      a{
        position: relative;
        display: inline-block;
        color: #fff;
        font-weight: 600;
        &:before{
          position: absolute;
          content: "";
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 1px;
          background: #fff;
          transform:scaleX(0);
          transition:inherit;
        }
        // @include mq(sm-){
        //   &:hover{
        //     &:before{
        //       transform:scale(1);
        //     }
        //   }
        // }
      }
    }
    @include mq(sm-){
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-left:solid 1px #fff;
    }
  }
  @include mq(sm-){
    // &__pulldown{
    //   position:absolute;
    //   left: 50%;
    //   top: 100%;
    //   min-width: 100%;
    //   transform: translateX(-50%);
    //   display: none;
    //   white-space:nowrap;
    //   >li{
    //   }
    // }
  }
  @include mq(sm){
    background: $main_color;
    padding: $margin_ss*2 + 7rem $margin_sm $margin_md;
    // &__pulldown{
    //   background:none;
    //   >li{
    //   }
    // }
    &__list{
      li{
        border-bottom:dashed 1px #fff;
        a{
          display: block;
          padding:1em 0;
          font-size: 1.125em;
        }
      }
    }
    &__contact{
      margin-top: $margin_sm;
    }
    &__tel{
      text-align: center;
      margin-top: $margin_ss;
      color: #fff;
      > em{
        display: block;
        font-style: normal;
        font-weight: 600;
        margin-top: .25em;
      }
    }
    &__sub{
      margin-top: $margin_sm;
      display: flex;
      justify-content: center;
      align-items: center;
      a{
        display: inline-block;
        color: #fff;
        &:nth-child(n+2){
          padding-left: 1em;
          margin-left: 1em;
          border-left:solid 1px #fff;
        }
      }
    }
    @include mq(xs-){
      width: 40vw;
    }
    @include mq(xs){
      &__list{
        @include mq(ss-){
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          > li{
            width: 48%;
          }

        }
      }
    }
  }
}
