@charset "utf-8";
/*============================================

  KV

============================================*/
/*-------------------------------
  KV > TOP
-------------------------------*/
.p-kv{
  background-position:  top ;
  background-repeat: no-repeat;
  background-size: cover;
  height: 54em;
  margin-left: 25em;
  position: relative;
  &::after{
    z-index: -2;
    position: absolute;
    content: '';
    width: 100%;
    height: 68%;
    background: $main_color;
    top: 50%;
    left: -25em;
    transform: translateY(-50%);
  }
  // &__img{
  //   position: absolute;
  //   width: 100%;
  //   opacity: 0;
  //   animation: change-img-anim 40s infinite;
  //   &:nth-of-type(1){
  //     animation-delay: 0s;
  //   }
  //   &:nth-of-type(2){
  //     animation-delay: 10s;
  //   }
  //   &:nth-of-type(3){
  //     animation-delay: 20s;
  //   }
  //   &:nth-of-type(4){
  //     animation-delay: 30s;
  //   }
  //   @keyframes change-img-anim {
  //     0%{ opacity: 0;}
  //     20%{ opacity: 1;}
  //     30%{ opacity: 1;}
  //     35%{ opacity: 0;}
  //     100%{ opacity: 0;}
  //   }
  // }
  &__ttl{
    z-index: 2;
    position: absolute;
    top: 50%;
    left: -22.5em;
    transform: translateY(-50%);
    color: #fff;
    em{
      font-family: $base_font_en;
      font-size: 5.625em;
      font-weight: 700;
      line-height: 1;
      text-align: left;
    }
    span{
      display: block;
      margin-top: .625em;
      font-weight: 500;
    }
  }
  @include mq(lg){
    height: 43.2em;
  }
  @include mq(md){
    height: 34.56em;
    margin-left: 20em;
    &::after{
      left: -20em;
    }
    &__ttl{
      left: -18em;
      em{
        font-size: 4.5em;
      }
    }
  }
  @include mq(sm){
    margin-left: 16em;
    &::after{
      left: -16em;
    }
    &__ttl{
      left: -14.4em;
      em{
        font-size: 4.2em;
      }
    }
  }
  @include mq(xs){
    height: 27.648em;
    margin-left: 0;
    &::after{
      width: 80%;
      height: 33%;
      left: 0;
      z-index: 1;
    }
    &__ttl{
      left: 2em;
      em{
        font-size: 3.6em;
        display: inline-block;
      }
      span{
        display: inline-block;
      }
    }
  }
  @include mq(ss){
    &::after{
      height: 40%;
    }
    &__ttl{
      left: 1.44em;
      em{
        font-size: 2.88em;
      }
      span{
        margin-top: .5em;
        line-height: 1.34;
      }
    }
  }
}

/*-------------------------------
  KV > UNDER
-------------------------------*/
.p-kv_under{
  background: $main_color;
  padding: 5.625em 0;
  @include mq(md){
    padding: 5em 0;
  }
  @include mq(xs){
    
  }
}

