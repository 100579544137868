@charset "utf-8";
/*============================================

  TABLE PRIMARY

============================================*/
.c-tbl{
  border-top:solid 1px $sub_color;
  tr{
    border-bottom:solid 1px $sub_color;
  }
  th,
  td{
    text-align: left;
    padding: 1.5em ;
    vertical-align: top;
  }
  th{
    background: $main-color;
    width: 20%;
    font-weight: 400;
    color: #fff;
    vertical-align: middle;
  }
  td{
    border-right: solid 1px $sub_color;
  }

  @include mq(xs){
    tr{
      display: flex;
      flex-direction: column;
    }
    th,
    td{
      width: 100%;
      padding: 1.2em 1.5em;
    }
    td{
      border-right: solid 1px $sub_color;
      border-left: solid 1px $sub_color;
    }
  }
}

/*============================================

  TABLE SUB

============================================*/
.c-tbl_sub{
  border-top:solid 1px $sub_color;
  border-left: solid 1px $sub_color;
  tr{
    border-bottom:solid 1px $sub_color;
  }
  th,
  td{
    text-align: left;
    padding: 1.5em ;
    vertical-align: top;
  }
  th{
    width: 20%;
    font-weight: 400;
    vertical-align: middle;
    background: #f7f7f7;
  }
  td{
    border-right: solid 1px $sub_color;
  }
  @include mq(xs){
    tr{
      display: flex;
      flex-direction: column;
    }
    th,
    td{
      width: 100%;
      padding: 1.2em 1.5em;
      border-right: solid 1px $sub_color;
    }
    th{
      background: $sub_color;
    }
  }

}