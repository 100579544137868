@charset "utf-8";
/*============================================

  CARD

============================================*/

/*-------------------------------
  CARD > NEWS
-------------------------------*/
.c-card_news{
  position: relative;
  border-top: 1px solid #E6E6E6;
  &:last-child{
    border-bottom:  1px solid #E6E6E6;
  }
  @include mq(sm-){
    &::before,
    &::after{
      display: block;
      z-index: 1;
      position: absolute;
      top: 0;
      width: 0;
      height: 100%;
      background: #f7f7f7;
      content: '';
      pointer-events: none;
    }
    &::before{
      transition: all .1s cubic-bezier(.25,.46,.45,.94);
      left: 0;
    }
    &::after{
      transition: all .4s cubic-bezier(.25,.46,.45,.94) .1s;
      right: 0;
    }
    &:hover::before{
      width: 100%;
      transition: all .4s cubic-bezier(.25,.46,.45,.94);
    }
    &:hover::after{
      width: 100%;
      transition: all .1s cubic-bezier(.25,.46,.45,.94) .4s;
    }
  }
  &__item{
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 1.875em;
    &::before{
      @include icon('arrow-fill_20');
      position: absolute;
      font-size: 1em;
      top: 50%;
      right: 1.875em;
      transform: translate(0,-50%);
    }
  }
  &__day{
    margin-right: 2.5em;
    font-weight: 600;
    font-family: $base_font_en;
  }
  &__ctg{
    font-size: .75em;
    margin-right: 1.6em;
    background: $main_color;
    color: #fff;
    padding: 0 1em;
  }
  &__txt{
    line-height: 1.875;
    width: 70%;
  }
  @include mq(md){
    &__item{
      &::before{
        right: 1.5em;
      }
    }
    &__day{
      margin-right: 2em;
    }
  }
  @include mq(sm){
    &__item{
      padding: 1.5em 1em ;
      &::before{
        font-size: .8em;
        right: 1em;
      }
    }
    &__day{
      margin-right: 1.8em;
    }
    &__ctg{
      margin-right: 1.28em;
    }
    &__txt{
      width: 66%;
    }
  }
  @include mq(xs){
    &__item{
      padding: 1.5em 2em 1.5em 1em;
      flex-wrap: wrap;
    }
    &__day{
      margin-right: 1.44em;
    }
    &__txt{
      width: 100%;
      margin-top: 0.25em;
    }
  }
}
